import { gettext, pgettext } from "#js/components/i18n"
import Shepherd from "shepherd.js"
import { ready } from "#js/components/utils"

export const MODAL_PADDING = {
  LARGE: 12,
  MEDIUM: 6,
}

export function createTour() {
  return new Shepherd.Tour({
    useModalOverlay: true,
    exitOnEsc: false,
    keyboardNavigation: false,
    defaultStepOptions: {
      arrow: false,
      scrollTo: {
        behavior: "smooth",
        block: "center",
      },
    },
  })
}

export function getButton(tour, button) {
  switch (button) {
    case "start":
      return {
        text: pgettext("verb", "start"),
        action: tour.next,
      }
    case "next":
      return {
        text: gettext("next"),
        action: tour.next,
      }
    case "cancel":
      return {
        text: pgettext("verb", "exit"),
        action: tour.cancel,
        classes: "cancel-tour",
      }
    case "done":
      return {
        text: gettext("done"),
        action: tour.complete,
      }
  }
}

/**
 * Initializes the tour upon cookie consent.
 *
 * This function checks if the cookie consent banner is no longer visible and if a cookie consent state is present.
 * It does not initiate the tour on small screens.
 * @param {Function} callback - The function to call when verification is positive.
 * @returns {void}
 */
export function initTourWithCookieConsent(callback) {
  const smallScreen = globalThis.innerWidth <= 1024
  if (smallScreen) return

  ready(function () {
    document.addEventListener("cookie-consent-submitted", () => {
      callback()
    })

    const cookieConsent = document.getElementById("cookie-consent")
    if (cookieConsent) {
      callback()
    }
  })
}
